<template>
	<sub-menu :data="data">
		<template #title>
			{{ data.title }}
		</template>
		<template v-for="item in data.children">
			<menu-item v-if="!item.children" :key="'menu-item-' + item.id" :data="item">
				{{ item.title }}
			</menu-item>
			<re-sub-menu v-else :key="'re-sub-menu-' + item.id" :data="item"></re-sub-menu>
		</template>
	</sub-menu>
</template>

<script>
	import SubMenu from "./SubMenu";
	import MenuItem from "./MenuItem";

	export default {
		name: "ReSubMenu",
		props: {
			data: Object,
		},
		components: {
			SubMenu,
			MenuItem,
		},
	};
</script>

<style scoped></style>
